body {
    background-color: white;
    /*overflow-x: overlay;*/
}

body::-webkit-scrollbar {
    height: 10px;
    width: 20px;
    background: white;
}
body::-webkit-scrollbar-track {

}
body::-webkit-scrollbar-thumb {
    border: 3px solid white;
    border-radius: 8px;
    background-color: #FF3F3F;
}

.custom-scroll-bar::-webkit-scrollbar {
    width: 8px;
    background: rgb(246 217 98 / 6%);
}
.custom-scroll-bar::-webkit-scrollbar-track {
    width: 8px;
}
.custom-scroll-bar::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #F6D962;
    border-radius: 999px;
}

.main-scroll-bar::-webkit-scrollbar {
    width: 10px;
    background: transparent;
}
.main-scroll-bar::-webkit-scrollbar-track {
    width: 10px;
}
.main-scroll-bar::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #282828;
    border-radius: 999px;
    border: 3px solid #F3F2F7;
}


ol {
    list-style: decimal;
    padding-left: 15px;
}

@media (max-width: 600px) {
    .main-scroll-bar::-webkit-scrollbar {
        width: 2px;
    }

    .main-scroll-bar::-webkit-scrollbar-track {
        width: 2px;
    }

    .main-scroll-bar::-webkit-scrollbar-thumb {
        width: 2px;
        background-color: #282828;
        border: none;
    }
}
